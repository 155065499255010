import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  wrapper: {},
  title: {
    fontFamily: "NewSpirit500",
    fontSize: 36,
    lineHeight: "45px",
    textAlign: "center",
    color: "#C29B81",
  },
  cards: {
    paddingTop: 60,
    maxWidth: 1100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    margin: "0 auto",
  },
  card: {
    padding: "12px 16px",
    // width: 170,
    // height: 96,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media(max-width:425px)": {
      paddingBottom: 16,
    },
  },
  cardTitle: {
    paddingTop: 28,
    textAlign: "center",
    fontFamily: "Proxima600",
    fontSize: 24,
    lineHeight: "29px",
  },
  cardImage: {
    "@media(max-width:400px)": {
      maxWidth: 300,
    },
  },
});
const INFO = [
  {
    id: 1,
    imagePath: "a",
  },
  {
    id: 2,
    imagePath: "b",
  },
  {
    id: 3,
    imagePath: "c",
  },
  {
    id: 4,
    imagePath: "d",
  },
  {
    id: 5,
    imagePath: "e",
  },
  {
    id: 6,
    imagePath: "f",
  },
  // {
  //   id: 7,
  //   imagePath: "g",
  // },
  // {
  //   id: 77,
  //   imagePath: "g",
  // },
  // {
  //   id: 8,
  //   imagePath: "i",
  // },
  // {
  //   id: 9,
  //   imagePath: "j",
  // },
  // {
  //   id: 10,
  //   imagePath: "k",
  // },
  // {
  //   id: 11,
  //   imagePath: "l",
  // },
  // {
  //   id: 12,
  //   imagePath: "m",
  // },
  // {
  //   id: 13,
  //   imagePath: "n",
  // },
  // {
  //   id: 14,
  //   imagePath: "o",
  // },
  // {
  //   id: 15,
  //   imagePath: "p",
  // },
];
const Brands = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <p className={classes.title}>Our Partners</p>
      <div className={classes.cards}>
        {INFO.map((brand) => (
          <div className={classes.card} key={brand.id}>
            <img
              src={require(`../../assets/images/about/brands/updated/${brand.imagePath}.png`)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Brands;
